import { Modal, ModalBody, ModalSection, ModalHeader, ModalInformation, Button, Text, ModalFooter, modalInstance } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";

const ExplainTemplateModal = ({ explainedTemplate, setExplainedTemplate }) => {
  return (
    <Modal modalId={MODAL_IDS.EXPLAIN_TEMPLATE}>
      <ModalBody>
        <ModalSection>
          <ModalInformation />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={"Template explained"} />
          <ul />
          {explainedTemplate.map((explanation) => {
            return <li>{explanation}</li>;
          })}
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            setExplainedTemplate([]);
            modalInstance(MODAL_IDS.EXPLAIN_TEMPLATE).toggle();
          }}
        >
          <Text size="sm">Exit</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ExplainTemplateModal };
