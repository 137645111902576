import React from "react";
import SuggestString from "./components/SuggestString";
import HeaderSelectorButton from "../selectandtransform/module/HeaderSelectorButton";
import { tbAppChannel } from "../utils/communication/AppChannels";
import { fundsDataTableChannel } from "../utils/communication/AppChannels";
import CommandArgsModel from "../utils/domain/CommandArgsModel";
import IndexedValue from "../utils/domain/selectandtransform/IndexedValue";
import { DropdownWithColumnMappings } from "./components/DropdownWithColumnMappings";
import { DropdownWithRuleValidation } from "./components/DropdownWithRuleValidation";
import { Menu, MenuItem, Button, Typography, TextField, Checkbox, ListItemText, Grid, IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

export default class CommandParamsInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mapList: [] };
    this.getAliasTableList = this.getAliasTableList.bind(this);
    this.handleDropdownMenuClick = this.handleDropdownMenuClick.bind(this);
    this.handleDropdownMenuClose = this.handleDropdownMenuClose.bind(this);
    this.onStringValueChange = this.onStringValueChange.bind(this);
  }

  render() {
    let commandArgsUi = this.props.commandParams.uiCommand.argsSpec;
    let argumentsValue = this.props.commandParams.userInputargs || {};
    let initStateParams = argumentsValue;

    let columnIndexFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "columnIndex";
    });
    let deletedColumnIndexFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "deletedColumnIndex";
    });
    let stringArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "string";
    });
    let booleanArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "boolean";
    });
    let dateFormatArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "dateFormat";
    });
    let headerArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "headers";
    });
    let dropdownArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "dropdown";
    });
    let mapListArgsFiltered = commandArgsUi.filter((argsNameWraper) => {
      return argsNameWraper.type === "mapList";
    });

    columnIndexFiltered.forEach((argsNameWraper) => {
      if (!(argumentsValue[argsNameWraper.peName] instanceof IndexedValue)) {
        if (Array.isArray(argumentsValue[argsNameWraper.peName])) {
          let indexedValues = [];
          argumentsValue[argsNameWraper.peName].forEach((element) => {
            if (element instanceof IndexedValue) {
              indexedValues.push(element);
            } else {
              indexedValues.push(
                new IndexedValue({
                  index: element,
                  value: this.props.commandResultArray[0].fundsData.headers[element],
                })
              );
            }
          });
          initStateParams[argsNameWraper.peName] = indexedValues;
        } else {
          let index = parseInt(argumentsValue[argsNameWraper.peName]);
          let value = this.props.commandResultArray[0].fundsData.headers[index];
          initStateParams[argsNameWraper.peName] = new IndexedValue({
            index: index,
            value: value,
          });
        }
      } else {
        initStateParams[argsNameWraper.peName] = argumentsValue[argsNameWraper.peName];
      }
    });

    deletedColumnIndexFiltered.forEach((argsNameWraper) => {
      const peName = argsNameWraper.peName;
      const indexAndValue = argumentsValue[peName];
      if (!(indexAndValue instanceof IndexedValue)) {
        const [index, value] = indexAndValue ? indexAndValue.split(",") : [undefined, undefined];
        initStateParams[peName] = new IndexedValue({ index, value });
      } else {
        initStateParams[peName] = indexAndValue;
      }
    });

    let columnIndexComponents = columnIndexFiltered.map((argsNameWraper, index) => {
      return (
        <div elevation={3} className="command-params-input" key={index}>
          <Grid container alignItems="center" direction="row" spacing={8} className="command-params-column-select">
            <Grid item xs={4}>
              <HeaderSelectorButton
                indexedValue={initStateParams[argsNameWraper.peName]}
                onSelection={this.headerValueReceived.bind(this, argsNameWraper.peName, initStateParams)}
                multiSelectionEnabled={this.props.multiSelectionEnabled}
              />
            </Grid>
            <Grid item xs={8}>
              <ListItemText
                primary={argsNameWraper.uiName}
                secondary={
                  Array.isArray(initStateParams[argsNameWraper.peName])
                    ? Array.from(initStateParams[argsNameWraper.peName].map((element) => element.value)).join()
                    : initStateParams[argsNameWraper.peName].value || "Nothing Selected"
                }
              />
            </Grid>
          </Grid>
        </div>
      );
    });

    let CommandParamInputsInitState = (argsNameWraper) => {
      if (initStateParams[argsNameWraper.peName].index == undefined) {
        return (
          <Grid item xs={4}>
            <HeaderSelectorButton
              indexedValue={initStateParams[argsNameWraper.peName]}
              onSelection={this.headerValueReceived.bind(this, argsNameWraper.peName, initStateParams)}
            />
          </Grid>
        );
      }
    };

    let deletedColumnIndexComponents = deletedColumnIndexFiltered.map((argsNameWraper, index) => {
      return (
        <div elevation={3} className="command-params-input" key={index}>
          <Grid container alignItems="center" direction="row" spacing={24} className="command-params-column-select">
            {CommandParamInputsInitState(argsNameWraper)}
            <Grid item xs={8}>
              <ListItemText primary={argsNameWraper.uiName} secondary={initStateParams[argsNameWraper.peName].value || "Nothing Selected"} />
            </Grid>
          </Grid>
        </div>
      );
    });

    let stringArgsComponents = stringArgsFiltered.map((argsNameWraper, index) => {
      let value =
        this.props.commandParams.userInputargs == undefined ? argsNameWraper.defaultValue : this.props.commandParams.userInputargs[argsNameWraper.peName];
      if (value == undefined) value = "";
      let lable = argsNameWraper.uiName;
      //If a default value exists on initialisation, set it as the user input
      if (argsNameWraper.defaultValue && this.props.commandParams.userInputargs == undefined) {
        this.onStringValueChange(null, argsNameWraper.peName, value);
      }
      return (
        <div elevation={3} className="command-params-input" key={index} data-name={argsNameWraper.peName}>
          <Typography title={argsNameWraper.uiDesc} className="command-params-title">
            {lable}
          </Typography>
          <SuggestString
            className="command-params-input-field"
            peName={argsNameWraper.peName}
            onChange={(event, name, value) => {
              this.onStringValueChange(event, name, value);
            }}
            allSuggestions={argsNameWraper.suggestions}
            value={value}
          ></SuggestString>
        </div>
      );
    });

    let booleanArgsComponents = booleanArgsFiltered.map((argsNameWraper, index) => {
      let value = this.props.commandParams.userInputargs == undefined ? "" : this.props.commandParams.userInputargs[argsNameWraper.peName];
      let boolVal = false;
      if (value == "1") {
        boolVal = true;
      }
      return (
        <div elevation={3} className="command-params-input" key={index}>
          <div className="command-params-input-field">
            <Typography>
              {argsNameWraper.uiName}
              <Checkbox
                defaultChecked={boolVal}
                label={argsNameWraper.uiName}
                key={index}
                className="command-params-input-field"
                data-name={argsNameWraper.peName}
                onChange={this.onBooleanValueChange.bind(this)}
              />
            </Typography>
          </div>
        </div>
      );
    });

    let dateFormatArgsComponents = dateFormatArgsFiltered.map((argsNameWraper, index) => {
      let value =
        this.props.commandParams.userInputargs == undefined ? argsNameWraper.defaultValue : this.props.commandParams.userInputargs[argsNameWraper.peName];
      return (
        <div elevation={3} className="command-params-input" key={index}>
          <TextField
            required={argsNameWraper.required}
            label={argsNameWraper.uiName}
            className="command-params-input-field"
            data-name={argsNameWraper.peName}
            value={value}
            onChange={this.onDateFormatOrHeadersValueChange.bind(this)}
          />
        </div>
      );
    });

    let headersArgsComponents = headerArgsFiltered.map((argsNameWraper, index) => {
      let value =
        this.props.commandParams.userInputargs == undefined ? argsNameWraper.defaultValue : this.props.commandParams.userInputargs[argsNameWraper.peName];
      if (value == undefined) value = "";
      let lable = argsNameWraper.uiName;
      return (
        <div elevation={3} className="command-params-input" key={index} data-name={argsNameWraper.peName}>
          <Typography className="command-params-title">{lable}</Typography>
          <TextField
            className="command-params-input-field"
            value={value}
            data-name={argsNameWraper.peName}
            onClick={this.selectHeader.bind(this)}
            onChange={this.onDateFormatOrHeadersValueChange.bind(this)}
          ></TextField>
        </div>
      );
    });

    // Currently dropdowns are only implemented for the alias table & rates file rules
    // to utilise the dropdown for another rule, this code must be modified
    let dropdownArgsComponents = dropdownArgsFiltered.map((argsNameWraper, index) => {
      if (argsNameWraper.peName == "aliasFileUuid") {
        return this.createDropdownForAliasTableRule(argsNameWraper, index);
      } else if (argsNameWraper.peName == "rateType") {
        return this.createDropdownForRateFileRule(argsNameWraper, index);
      } else if (argsNameWraper.peName == "columnType") {
        return this.createDropdownForSortValue(argsNameWraper, index);
      } else if (argsNameWraper.peName == "sortType") {
        return this.createDropdownForSortType(argsNameWraper, index);
      } else if (argsNameWraper.peName == "dropdownWithColumnMappings") {
        return (
          <DropdownWithColumnMappings
            argsNameWraper={argsNameWraper}
            index={index}
            uiCommand={this.props.commandParams.uiCommand}
            userInputArgs={this.props.commandParams.userInputargs}
            isNew={this.props.commandParams.isNew}
          />
        );
      } else if (argsNameWraper.peName == "dropdownWithRuleValidation") {
        return (
          <DropdownWithRuleValidation
            argsNameWraper={argsNameWraper}
            index={index}
            uiCommand={this.props.commandParams.uiCommand}
            userInputArgs={this.props.commandParams.userInputargs}
            isNew={this.props.commandParams.isNew}
          />
        );
      }
    });

    const mapListComponent = (argsNameWraper, index) => {
      const addObjectToList = () => {
        let list = this.state.mapList;
        list.push({ name: "", path: "" });
        this.setState({ mapList: list });
      };

      const removeObjectFromList = () => {
        let list = this.state.mapList;
        list.pop();
        this.setState({ mapList: list });
      };

      const updateMapList = (index, keyValue, value) => {
        let updatedObject = { name: "", path: "" };
        let list = this.state.mapList;
        let objEntries = Object.entries(list[index]);
        if (keyValue === "name") {
          updatedObject.name = value;
          updatedObject.path = objEntries[1][1];
        } else {
          updatedObject.name = objEntries[0][1];
          updatedObject.path = value;
        }
        list[index] = updatedObject;
        this.setState({ mapList: list });

        let args = this.props.commandParams.userInputargs || {};
        args[argsNameWraper.peName] = list;
        tbAppChannel.publish(
          "onCommandParamsChange",
          new CommandArgsModel({
            uiCommand: this.props.commandParams.uiCommand,
            userInputargs: args,
            isNew: this.props.commandParams.isNew,
          })
        );
      };

      return (
        <div elevation={3} className="command-params-input" key={index} data-name={argsNameWraper.peName}>
          <Typography title={argsNameWraper.uiDesc} className="command-params-title">
            {argsNameWraper.uiName}
          </Typography>
          {this.state.mapList.map((objectData, index) => {
            const name = Object.entries(objectData)[0];
            const path = Object.entries(objectData)[0];
            return (
              <Grid container alignItems="center" direction="row" spacing={24}>
                <Grid item xs={6}>
                  <Typography className="command-params-title">Name</Typography>
                  <SuggestString
                    className="command-params-input-field"
                    peName={argsNameWraper.peName}
                    onChange={(event, name, value) => {
                      updateMapList(index, "name", value);
                    }}
                    allSuggestions={argsNameWraper.suggestions}
                    value={name[1]}
                  ></SuggestString>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="command-params-title">Value</Typography>
                  <SuggestString
                    className="command-params-input-field"
                    peName={argsNameWraper.peName}
                    onChange={(event, name, value) => {
                      updateMapList(index, "path", value);
                    }}
                    allSuggestions={argsNameWraper.suggestions}
                    value={path[1]}
                  ></SuggestString>
                </Grid>
              </Grid>
            );
          })}
          <IconButton
            title="Add a command here"
            data-index={index}
            onClick={() => {
              addObjectToList();
            }}
          >
            <Icon>add</Icon>
          </IconButton>
          <IconButton
            title="Add a command here"
            data-index={index}
            onClick={() => {
              removeObjectFromList();
            }}
          >
            <Icon>remove</Icon>
          </IconButton>
        </div>
      );
    };

    const mapListArgsComponents = mapListArgsFiltered.map((argsNameWraper, index) => {
      let uiText =
        !this.props.commandParams.userInputargs || this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined
          ? argsNameWraper.defaultValue
          : this.props.commandParams.userInputargs[argsNameWraper.peName];
      return mapListComponent(argsNameWraper, index);
    });

    return (
      <div>
        {columnIndexComponents}
        {deletedColumnIndexComponents}
        {stringArgsComponents}
        {booleanArgsComponents}
        {dateFormatArgsComponents}
        {headersArgsComponents}
        {dropdownArgsComponents}
        {mapListArgsComponents}
      </div>
    );
  }

  /**
   * Return the dropdown HTML for the alias table rule
   * @param argsNameWraper
   * @param index
   * @returns {*}
   */
  createDropdownForAliasTableRule(argsNameWraper, index) {
    let uiText =
      !this.props.commandParams.userInputargs || this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined
        ? argsNameWraper.defaultValue
        : this.findAliasTableNameById(this.props.commandParams.userInputargs[argsNameWraper.peName]);
    return this.createDropdown(uiText, argsNameWraper.peName, this.getAliasTableList(argsNameWraper.peName, uiText), index);
  }

  /**
   * Return the dropdown HTML for the rate file rule
   * @param argsNameWraper
   * @param index
   * @returns {*}
   */
  createDropdownForRateFileRule(argsNameWraper, index) {
    let uiText =
      !this.props.commandParams.userInputargs || this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined
        ? argsNameWraper.defaultValue
        : this.props.commandParams.userInputargs[argsNameWraper.peName];
    return this.createDropdown(uiText, argsNameWraper.peName, this.getRateTypeList(argsNameWraper.peName, uiText), index);
  }

  createDropdownForSortValue(argsNameWraper, index) {
    let uiText =
      !this.props.commandParams.userInputargs || this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined
        ? "Sort Value"
        : this.props.commandParams.userInputargs[argsNameWraper.peName];
    return this.createDropdown(uiText, argsNameWraper.peName, this.getSortValueList(argsNameWraper.peName, uiText), index);
  }

  createDropdownForSortType(argsNameWraper, index) {
    let uiText =
      !this.props.commandParams.userInputargs || this.props.commandParams.userInputargs[argsNameWraper.peName] == undefined
        ? "Sort Type"
        : this.props.commandParams.userInputargs[argsNameWraper.peName];
    return this.createDropdown(uiText, argsNameWraper.peName, this.getSortTypeList(argsNameWraper.peName, uiText), index);
  }

  /**
   * Create the HTML for a dropdown button and menu
   * @param uiText text to be displayed
   * @param peName name of the PE rule
   * @param dropdownList List of HTML elements to populate the dropdown menu
   * @param index
   * @returns {XML}
   */
  createDropdown(uiText, peName, dropdownList, index) {
    return (
      <div elevation={3} className="command-params-input" key={index} data-name={peName}>
        <Button
          className="dropdownButton"
          disabled={this.state.selected}
          onClick={(event) => {
            this.handleDropdownMenuClick(peName, event);
          }}
        >
          {peName == "rateType" ? uiText.replace("_", " ") : uiText}
        </Button>
        <Menu
          id="alias-menu"
          open={this.state[`${peName}MenuOpen`]}
          onClose={() => {
            this.handleDropdownMenuClose(peName);
          }}
          anchorEl={this.state[`${peName}AnchorEl`]}
        >
          {dropdownList}
        </Menu>
      </div>
    );
  }

  /**
   * When dropdown item selected, update tbApp.state and close the menu
   * @param peName peName for the arg
   * @param uuid UUID of the alias table
   */
  dropdownSelection(peName, uuid) {
    let args = this.props.commandParams.userInputargs || {};
    args[peName] = uuid;
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: args,
        isNew: this.props.commandParams.isNew,
      })
    );
    let stateObj = {};
    stateObj[`${peName}MenuOpen`] = false;
    this.setState(stateObj);
  }

  handleDropdownMenuClose(peName, event) {
    let stateObj = {};
    stateObj[`${peName}MenuOpen`] = false;
    this.setState(stateObj);
  }

  headerValueReceived(argumentPeName, initStateParams, indexedValueSelected) {
    initStateParams[argumentPeName] = indexedValueSelected;
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: initStateParams,
        isNew: this.props.commandParams.isNew,
      })
    );
  }

  onStringValueChange(event, valName, newValue) {
    let params = this.props.commandParams.userInputargs || {};
    params[valName] = newValue;
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: params,
        isNew: this.props.commandParams.isNew,
      })
    );
  }

  onBooleanValueChange(event) {
    let params = this.props.commandParams.userInputargs || {};
    let boolValuesAsIntString = "0";
    if (event.target.checked) {
      boolValuesAsIntString = "1";
    }
    params[event.target.parentElement.parentElement.dataset.name] = boolValuesAsIntString;
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: params,
        isNew: this.props.commandParams.isNew,
      })
    );
  }

  onDateFormatOrHeadersValueChange(event) {
    let params = this.props.commandParams.userInputargs || {};
    params[event.target.parentElement.parentElement.dataset.name] = event.target.value;
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: params,
        isNew: this.props.commandParams.isNew,
      })
    );
  }

  // when user clicks on the Textfield , first unsubscribe, to avoid multiple subscriptions
  selectHeader(event) {
    try {
      this.onHeaderClickedSubscription.unsubscribe();
    } catch (err) {}
    this.onHeaderClickedSubscription = fundsDataTableChannel.subscribe("headerClicked", this.onHeaderClicked.bind(this));
    document.querySelector(".structure-table").classList.add("header-clickable");
    this.setState({
      headersPeName: event.target.parentElement.parentElement.dataset.name,
    });
  }

  onHeaderClicked(data, envelope) {
    let indexedValue = new IndexedValue({
      index: data.event.target.cellIndex,
      value: data.event.target.innerHTML,
    });
    let params = this.props.commandParams.userInputargs || {};
    if (params[this.state.headersPeName] == undefined) {
      params[this.state.headersPeName] = indexedValue.value;
    } else {
      params[this.state.headersPeName] = params[this.state.headersPeName] + "," + indexedValue.value;
    }
    tbAppChannel.publish(
      "onCommandParamsChange",
      new CommandArgsModel({
        uiCommand: this.props.commandParams.uiCommand,
        userInputargs: params,
        isNew: this.props.commandParams.isNew,
      })
    );
  }

  /**
   * Create a list of MenuItems, each to represent an Alias table which has not been deleted
   * @param peName peName for the arg`
   * @param currentlySelectedAliasTableName
   * @returns {*}
   */
  getAliasTableList(peName, currentlySelectedAliasTableName) {
    return this.props.commandParams.uiCommand.dropDownKeyValuePair.map((aliasTable, index) => {
      if (aliasTable.deleted) {
        return null;
      } else {
        return this.createDropdownItem(index, currentlySelectedAliasTableName, peName, aliasTable.name, aliasTable.uuid, false);
      }
    });
  }

  getRateTypeList(peName, currentlySelectedRateType) {
    return this.props.commandParams.uiCommand.dropDownKeyValuePair.map((rateType, index) => {
      return this.createDropdownItem(index, currentlySelectedRateType, peName, rateType.name, false, true);
    });
  }

  getSortValueList(peName, currentlySelectedValue) {
    return ["Number", "Date", "Text"].map((value, index) => {
      return this.createDropdownItem(index, currentlySelectedValue, peName, value, false, true);
    });
  }

  getSortTypeList(peName, currentlySelectedValue) {
    return ["Ascending", "Descending"].map((value, index) => {
      return this.createDropdownItem(index, currentlySelectedValue, peName, value, false, true);
    });
  }

  createDropdownItem(index, currentlySelected, peName, value, uuid = false, replaceUnderscores) {
    return (
      <MenuItem key={index} selected={currentlySelected === value} onClick={(event) => this.dropdownSelection(peName, uuid || value)}>
        {replaceUnderscores ? value.replace("_", " ") : value}
      </MenuItem>
    );
  }

  /**
   * Retrieve the alias table name from it's UUID
   * @param id Alias table UUID
   * @returns {*}
   */
  findAliasTableNameById(id) {
    for (let aliasTable of this.props.commandParams.uiCommand.dropDownKeyValuePair) {
      if (aliasTable.uuid == id) {
        return aliasTable.name;
      }
    }
  }

  /**
   * When the dropdown button clicked, sets the anchorElement for the menu and sets peName-MenuOpen to true
   * @param event
   */
  handleDropdownMenuClick(peName, event) {
    let stateObj = {};
    stateObj[`${peName}MenuOpen`] = true;
    stateObj[`${peName}AnchorEl`] = event.currentTarget;
    this.setState(stateObj);
  }

  componentWillUnmount() {
    if (this.onHeaderClickedSubscription != undefined) {
      this.onHeaderClickedSubscription.unsubscribe();
      document.querySelector(".structure-table").classList.remove("header-clickable");
    }
  }
}
